<template>
  <div class="tabs-material-subtitle">
    <Container>
      <h2>Conditions particulières d'emploi des produits phytosanitaires</h2>
    </Container>
  </div>

  <div class="key-values margin-bottom">
    <KeyValue
      table
      label="Texte d'information"
      value="Les conditions et précautions d’emploi à prendre pour les produits phytopharmaceutiques
       sont celles indiquées sur les fiches de données de sécurité et
       dans les « Consignes de sécurité ».
       Rappel : le port des EPI réglementaires est obligatoire lors de toutes les phases
       de manipulation des produits, bouillies, rinçage du matériel, ..."
    />
    <KeyValue
      table
      :label="`L’utilisation de produits phytosanitaires issus du stock interne de
        l’organisme est tolérée, sous réserve de la bonne conservation du produit`"
      type="input">
      <Radio
        id="utilisation_phyto"
        :items="[
          { label: 'Oui', value: true },
          { label: 'Non', value: false },
        ]"
        inputStyle="inline"
      />
    </KeyValue>
    <KeyValue v-if="isShared" table label="Mode d'application"
              :value="protocol.mode_appl_phyto"
    />
    <KeyValue v-else-if="isBpe" table
              label="Mode d’application" type='input' required>
      <Input id="mode_appl_phyto"/>
    </KeyValue>
    <KeyValue v-else table
              label="Mode d’application" type='input'>
      <Input id="mode_appl_phyto"/>
    </KeyValue>

    <KeyValue v-if="isBpe" table label="Volume de bouillie" type="input" required>
      <Input id="volume_bouillie"/>
    </KeyValue>
    <KeyValue v-else table label="Volume de bouillie" type="input">
      <Input id="volume_bouillie"/>
    </KeyValue>

    <KeyValue v-if="isBpe" table child label="Unité" type="input" required>
      <SelectExtended
        id="unite_uid"
        optionKey="uid"
        optionValue="valeur"
        apiEndpoint="unite"
      />
    </KeyValue>
    <KeyValue v-else table child label="Unité" type="input">
      <SelectExtended
        id="unite_uid"
        optionKey="uid"
        optionValue="valeur"
        apiEndpoint="unite"
      />
    </KeyValue>

    <KeyValue v-if="isBpe" table
              label="Observations" type='input' required>
      <Input id="observations" type="textarea"/>
    </KeyValue>
    <KeyValue v-else table
              label="Observations" type='input'>
      <Input id="observations" type="textarea"/>
    </KeyValue>

    <KeyValue v-if="isShared" table label="Contamination artificielle"
              :value="protocol.contamination_artificielle ? 'Oui' : 'Non'"/>
    <KeyValue v-else
      table
      label="Contamination artificielle"
      type="input">
      <Radio
        id="contamination_artificielle"
        :items="[
          { label: 'Oui', value: true },
          { label: 'Non', value: false },
        ]"
        inputStyle="inline"
      />
    </KeyValue>

    <KeyValue v-if="isShared" table label="Brumisation"
              :value="protocol.brumisation ? 'Oui' : 'Non'"/>
    <KeyValue v-else
      table
      label="Brumisation"
      type="input">
      <Radio
        id="brumisation"
        :items="[
          { label: 'Oui', value: true },
          { label: 'Non', value: false },
        ]"
        inputStyle="inline"
      />
    </KeyValue>

    <KeyValue v-if="isBpe" table
      label="Methode CEB de référence" type='input' required>
      <Input id="methode_ceb_reference" type="textarea"/>
    </KeyValue>
    <KeyValue v-else table
              label="Methode CEB de référence" type='input'>
      <Input id="methode_ceb_reference" type="textarea"/>
    </KeyValue>

    <KeyValue v-if="isBpe" table
              label="Methodes internes" type='input' required>
      <Input id="methodes_internes" type="textarea"/>
    </KeyValue>
    <KeyValue v-else table
      label="Methodes internes" type='input'>
      <Input id="methodes_internes" type="textarea"/>
    </KeyValue>

    <KeyValue v-if="isBpe" table
      label="Guidelines OEPP de référence" type='input' required>
      <Input id="guidelines_oepp_reference" type="textarea"/>
    </KeyValue>
    <KeyValue v-else table
              label="Guidelines OEPP de référence" type='input'>
      <Input id="guidelines_oepp_reference" type="textarea"/>
    </KeyValue>
  </div>

  <Section>
    <Container>
      <h2>Produits non-homologués</h2>
    </Container>
    <div v-for="(up, index) in unhomologedProducts" :key="index">
      <Container>
        <div class="key-values-title">
          <h3>Produit #{{index+1}}</h3>
          <Btn
            text="Éditer"
            color="primary"
            @click="openModal('createUnhomologedProduct', up, index)"
          />
          <Btn
            text="Supprimer"
            @click="openModal('deleteUnhomologedProduct', up)"
            hollow
          />
        </div>
      </Container>
      <div class="key-values">
        <KeyValue table label="Produit" :value="up.produit.designation"/>
        <KeyValue table
                  label="Dérogation à la destruction de récolte"
                  :value="up.derogation_destruction_recolte ? 'Oui' : 'Non'"

        />
        <KeyValue table
                  label="Permis d’expérimenter ou n° d’ADE"
                  :value="up.permis_experimenter_ade"/>
        <KeyValue table
                  label="Réexpédition des produits au fabricant"
                  :value="up.ref_expedition_produits_fabricant"/>
      </div>
    </div>
    <Container>
      <div v-if="!unhomologedProducts.length">
        <p>Aucun produit non-homologué n'est renseigné pour le moment.</p>
      </div>
      <div class="margin-top">
        <Btn
          text="Ajouter un produit"
          color="primary"
          hollow
          icon="plus"
          icon-after
          @click="openModal('createUnhomologedProduct')"
        />
      </div>
    </Container>
  </Section>

  <!-- Modals modalCreateUnhomologatedProduct-->
  <Modal
    :title="modalCreateUnhomologedProductTitle"
    :active="modal.createUnhomologedProduct"
    :data="modalData"
    @modal-close="modal.createUnhomologedProduct = false"
    :containForm="true"
    :modalValidationSchema="validationSchema[6]"
    @modal-form-submitted="createUnhomologedProduct($event)"
  >
    <template v-slot:modal-body>
      <KeyValue table label="Nom du produit" type="input" >
        <SelectExtended
          id="produit_id"
          optionKey="id"
          optionValue="designation"
          :items="unhomologedProductsModal"
        />
      </KeyValue>
      <KeyValue table label="Dérogation à la destruction de récolte" type="input" >
        <Radio
          id="derogation_destruction_recolte"
          :items="[
            { label: 'Oui', value: true },
            { label: 'Non', value: false },
          ]"
          inputStyle="inline"
        />
      </KeyValue>
      <KeyValue table label="Permis d’expérimenter ou n° d’ADE" type="input" >
        <Input id="permis_experimenter_ade"/>
      </KeyValue>
      <KeyValue table label="Réexpédition des produits au fabricant" type="input" >
        <Input id="ref_expedition_produits_fabricant"
        />
      </KeyValue>

    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.createUnhomologedProduct = false" />
      <Btn
        btnType="submit"
        text="Enregistrer"
        color="primary"
      />
    </template>
  </Modal>

  <!-- Modals modalDeleteUnhomologatedProduct-->
  <Modal
    title="Supprimer"
    :active="modal.deleteUnhomologedProduct"
    :data="modalData"
    modalAction= 'delete'
    @modal-close="modal.deleteUnhomologedProduct = false"
  >
    <template v-slot:modal-body>
      <p>
        Voulez vous vraiment supprimer ce produit&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.deleteUnhomologedProduct = false" />
      <Btn
        text="Supprimer"
        @click="deleteUnhomologedProduct()"
        color="primary"
      />
    </template>
  </Modal>

</template>

<script>
import SelectExtended from '@/components/form/SelectExtended.vue'
import KeyValue from '@/components/form/KeyValue.vue'
import Input from '@/components/form/Input.vue'
import Radio from '@/components/form/Radio.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Btn from '@/components/base/Btn.vue'
import Modal from '@/components/layout/Modal.vue'

export default {
  name: 'PhytosanitaryProductsAddEditSubview',

  components: {
    Radio,
    SelectExtended,
    KeyValue,
    Input,
    Section,
    Container,
    Btn,
    Modal,
  },

  props: {
    pageTitle: {
      type: String,
    },
    validationSchema: {
      type: Array,
      default: undefined,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  data(props) {
    const { validationSchema } = props
    const vs = validationSchema[0].concat(validationSchema[3])
    const { isSubmitting, errors } = this.formService.initFrom(
      vs,
    )
    return {
      vs,
      isSubmitting,
      errors,
      modal: {
        createUnhomologedProduct: false,
        deleteUnhomologedProduct: false,
      },
      unhomologedProducts: [],
      unhomologedProductsModal: [],
      products: [],
      protocol: {},
      form: {
        references: [],
        thematiques: [],
      },
      schema: {},
      unhomologedProductsEndpoint: 'dictionnaire/statut/produit_non_homologue',
      componentKey: 0,
      modalData: null,
      step: 5,
      routeName: this.$route.name,
      previousRouteName: null,
      nextRouteName: null,
      method: 'post',
      pId: null,
      modalCreateUnhomologedProductTitle: 'Ajout d\'un produit non-homologué',
    }
  },

  emits: [
    'set-step',
    'populate',
    'set-route',
    'form-submitted',
    'set-submitted',
  ],

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.previousRouteName = from.name
    })
  },

  computed: {
    isBpe() {
      return this.protocol?.bpe
    },
    isShared() {
      return (this.protocol.date_partage !== null && this.protocol.partage_accepte)
    },
  },

  watch: {
    'modal.createUnhomologedProduct': {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.componentKey += 1
        })
      },
    },

    modelValue(newVal) {
      this.$nextTick(() => {
        this.componentKey += 1
      })
      // watch it
      if (newVal) {
        const { values } = this.formService
        const schema = this.protocolService.getSchema()
        const datas = {}

        Object.keys(this.vs.fields).forEach((node) => {
          if (node in values) {
            datas[node] = values[node]
          } else if (node in schema) {
            datas[node] = datas[node] ? datas[node] : schema[node]
          }
        })

        this.formService.setValues(datas)

        this.formService.validate(this.formService.values).then((errors) => {
          if (errors.valid) {
            this.$emit('form-submitted', this.formService.values)
          } else {
            this.formService.handleErrors(true)
            this.$emit('set-submitted')
          }
        })
      }
    },
  },

  async mounted() {
    this.emitter.emit('open-loader')
    const routeParams = {}

    this.schema.thematique_uid = {}

    if (this.$route.params.id) {
      routeParams.id = this.$route.params.id
    }

    if (this.$route.name.includes('Edit')) {
      this.previousRouteName = 'protocolCoverageExperimentEdit'
      this.nextRouteName = 'protocolStatisticalTestsEdit'
    } else {
      this.previousRouteName = 'protocolCoverageExperimentAdd'
      this.nextRouteName = 'protocolStatisticalTestsAdd'
    }

    this.$emit('set-step', this.step)
    this.$emit('set-route', {
      routeName: this.routeName,
      previousRouteName: this.previousRouteName,
      nextRouteName: this.nextRouteName,
      routeParams,
    })

    this.protocol = await this.getProtocol()
    this.unhomologedProducts = await this.getUnhomologedProducts()
    this.emitter.emit('close-loader')
  },

  methods: {
    openModal(modal, data = {}, index) {
      if ('id' in data) {
        if (modal === 'createUnhomologedProduct') {
          this.method = 'put'
          this.modalCreateUnhomologedProductTitle = 'Modifier un produit non-homologué'
          // eslint-disable-next-line no-param-reassign
          data.index = index
          // eslint-disable-next-line no-param-reassign
          data.produit_id = {
            key: data.produit?.id,
            value: data.produit?.designation,
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          data.modalAction = 'delete'
        }
        this.pId = data.id
      } else {
        // eslint-disable-next-line no-param-reassign
        data.index = this.unhomologedProducts.length
        this.method = 'post'
        this.pId = null
        this.fetchService.get(`produit?filters=passage.facteur.protocole.id:eq(${this.$route.params.id})`)
          .then((response) => {
            // Remove duplicates
            this.unhomologedProductsModal = response.data
              .filter((a, b, self) => self.findIndex((c) => (c.id === a.id)) === b)
          })
      }
      this.modalData = data
      this.modal[modal] = true
    },

    createUnhomologedProduct(event) {
      if (this.method === 'put') {
        this.handleSubmit(event, 'put', this.pId)
      } else {
        this.handleSubmit(event, 'post')
      }
    },

    async deleteUnhomologedProduct() {
      this.emitter.emit('open-loader')

      let { unhomologedProductsEndpoint } = this
      if (this.modalData.id) {
        unhomologedProductsEndpoint = `${unhomologedProductsEndpoint}/${this.modalData.id}`
      }

      const pIndex = this.unhomologedProducts.findIndex(
        (p) => parseInt(p.id, 10) === parseInt(this.modalData.id, 10),
      )

      this.fetchService.delete(`${unhomologedProductsEndpoint}`).then(
        () => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le produit a bien été supprimé.',
          })

          this.unhomologedProducts.splice(pIndex, 1)
          this.emitter.emit('close-loader')
        },
        (responseError) => {
          this.emitter.emit('alert', {
            type: 'error',
            content: 'data' in responseError ? responseError.data : responseError.message,
          })
          this.emitter.emit('close-loader')
        },
      )

      this.unhomologedProducts = await this.getUnhomologedProducts()
      this.modalData = {}
      this.modal.deleteUnhomologedProduct = false
    },

    async getProtocol() {
      const response = await this.fetchService.get(`protocole/${this.$route.params.id}`)

      return response.data
    },

    async getProducts() {
      const response = await this.fetchService.get('produit')
      return response.data
    },

    async getUnhomologedProducts() {
      const response = await this.fetchService.get(
        `${this.unhomologedProductsEndpoint}?filters=protocole.id:eq(${this.$route.params.id})`,
      )
      this.$emit('populate', this.protocol)
      return response.data
    },

    async handleSubmit(values, method = 'post', pId = null) {
      let { unhomologedProductsEndpoint } = this
      if (pId) {
        unhomologedProductsEndpoint = `${unhomologedProductsEndpoint}/${pId}`
      }
      // eslint-disable-next-line no-param-reassign
      values.produit_id = values.produit_id?.key
      // eslint-disable-next-line no-param-reassign
      values.protocole_id = this.$route.params.id

      const response = await this.fetchService[method](unhomologedProductsEndpoint, values)
      const up = await response.data

      if (this.method === 'put') {
        const pIndex = this.unhomologedProducts.findIndex(
          (p) => parseInt(p.id, 10) === parseInt(pId, 10),
        )
        this.unhomologedProducts[pIndex] = up
      } else {
        this.unhomologedProducts.push(up)
      }

      this.modal.createUnhomologedProduct = false
    },
  },
}
</script>

<style lang="scss" scoped>
.key-values-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: $gutter-half;
  margin-top: $gutter-and-half;
  margin-bottom: $gutter-half;

  > h3 {
    flex-grow: 1;
    margin-bottom: 0;
  }
}
</style>
